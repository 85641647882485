/**
 * Wysiwyg
 */

import React, {useState} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import parse, {domToReact} from 'html-react-parser';
import EmbedMessage from 'Components/EmbedMessage';
import s from './Wysiwyg.module.scss';

const Wysiwyg = ({modifier = '', wysiwyg = '', className = ''}) => {
    const [showMsg, setShowMsg] = useState(true);

    const classes = classNames(
        s['Wysiwyg'],
        {[s[`Wysiwyg--${modifier}`]]: modifier},
        className,
    );

    const parsed = parse(wysiwyg, {
        replace(node) {
            const {type, name, attribs} = node;
            if(showMsg && type === 'tag' && name === 'iframe') {
                const {src} = attribs;
                if(src.includes('youtube')) {
                    return (
                        <div className={s['Wysiwyg__Message']}>
                            <EmbedMessage
                                src={src}
                                category="targeting"
                                hideMessage={() => setShowMsg(false)}
                            />
                        </div>
                    );
                } else if(src.includes('vimeo') && !src.includes('dnt=1')) {
                    node.attribs.src = `${src}&dnt=1`;
                    return domToReact(node);
                }
            }
        },
    });

    return (
        <div className={classes}>
            {parsed}
        </div>
    );
};

Wysiwyg.propTypes = {
    wysiwyg: PropTypes.string,
    modifier: PropTypes.string,
};

export default Wysiwyg;
